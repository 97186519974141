/* Editable */

.waviy {
  position: relative;
  -webkit-box-reflect: below -1.25rem linear-gradient(transparent, rgba(0,0,0,.2));
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 2.5rem;
  color: #000;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i))
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-1.25rem)
  }
}


#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
	z-index: 99999;
    left: 0;
	display:flex;
	align-items:center;
	justify-content:center;
    top: 0;
    background-color: $white;
}

[data-theme-version="dark"]{
	#preloader {
		background:$d-bg;
	}
	.waviy span {
		color:$white;
	}
}

