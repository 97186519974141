.header-right {
    height: 100%;
	
	
    .nav-item {
        height: 100%;
        display: flex;
        align-items: center;
        .nav-link {
            color: $l-ctd;
            font-size: 1.125rem;
        }
    }
	.right-sidebar{
		margin-right:-1.875rem;
		a{
			height: 5rem;
			width: 5rem;
			text-align: center;
			justify-content: center;
			display: flex;
			align-items: center;
			border-left: 0.0625rem solid $light;
		}
	}
    &>li {
        &:not(:first-child) {
            padding-left:1.25rem;
			@include respond('tab-land') {
				padding-left:0.5rem;
			}
        }
    }
    .notification_dropdown {
        @include respond('phone-land') {
            position: static;
        }
        .nav-link {
            position: relative;
            color: var(--primary);
			background: transparent;
			border-radius: 1.375rem;
			background:$white;
			box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
			padding: 1rem;
			line-height: 1;
			 @include respond('phone-land') {
				padding:0.625rem;
			 }
			
            i {
                font-size: 1.5rem;
				@include respond('laptop') {
					font-size: 1.125rem;
				}
				
            }
			svg{
				path{
					fill:var(--primary);	
				}
				@include respond('laptop') {
					width:1.5rem;
					height:1.5rem;
				}
			}
            .badge {
                position: absolute;
                font-size: 0.625rem;
                border-radius: 50%;
                right: -0.5rem;
                top: -0.25rem;
				border:0.125rem solid $white;
                font-weight: normal;
                height: 1.625rem;
                width: 1.625rem;
                line-height: 0.75rem;
                text-align: center;
                padding: 0.3125rem;
				font-size: 0.875rem;
				@include respond('phone') {
					height: 1.25rem;
					width: 1.25rem;
					border-width: 0.125rem;
					line-height: 0.4375rem;
					font-size: 0.5625rem;
				}
            }
        }
        .dropdown-item {
            &:focus,
            &:active {
                a {
                    color: $white;
                }
            }
            a {
                color: $dark;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    .dropdown-menu {
        border-width:0;
        box-shadow: 0 0 2.3125rem rgba(8,21,66,0.05);
        @at-root [data-theme-version="dark"] & {
            box-shadow: none;
        }
    }
	.header-profile{
		.nav-link{
			display:flex;
			align-items:center;
			padding: 0;
			border-left: 0.0625rem solid $border-color;
			padding-left: 1.875rem;
			margin-left: 0.625rem;
			img{
				border-radius:$radius;
				height:3.5rem;
				width:3.5rem;
				@include respond ('phone'){
					height:38px;
					width:38px;
				}
			}
			.header-info{
				span{
					display:block;
					font-size:1.125rem;
					line-height: 1.125rem;
					color:#2E2E2E;
					font-weight: 600;
				}
				small{
					font-size:0.75rem;
					color:#2E2E2E;
				}
				@include respond ('phone'){
				  display:none;	
				}
			}
			@include respond ('phone'){
				padding-left: 0;
			}
		}
	}
}
.search-area{
		max-width:25rem;
		border-radius:$radius;
		box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
		.form-control{
			border:0;
			font-size:1rem;
			caret-color: var(--primary);
			background:$white;
			border-top-left-radius: $radius;
			border-bottom-left-radius: $radius;
			@include respond ('laptop'){
				height: 3.75rem;	
			}
		}
		.input-group-text{
			border-top-right-radius: $radius;
			border-bottom-right-radius: $radius;
			background:$white;
			border:0;
			i{
				font-size:1.5625rem;
				color:var(--primary);
			}
		}
	}
.nav-item{
	.search-area{
		@include respond('tab-land') {
			display:none;
		}	
	}	
}	
.dz-fullscreen{
	#icon-minimize{
		display:none;
	}
	&.active{
		#icon-full{
			display:none;
		}
		#icon-minimize{
			display:inline-block;
		}
	}
}
.notification_dropdown {
    .dropdown-menu-end {
        min-width: 19.375rem;
        padding: 0rem 0 1rem;
        top: 100%;
		
        .notification_title {
            background: var(--primary);
            color: $white;
            padding: 0.625rem 1.25rem;
            h5 {
                color: $white;
                margin-bottom: 0.1875rem;
            }
        }
        .media {
			width: 2.8125rem ;
			height: 2.8125rem ;
			font-size: 1.125rem ;
			
            // &:last-child{
            //     border-bottom: 0rem;
            // }
            @at-root [data-theme-version="dark"] & {
                border-color: $d-border;
            }
            &>span {
                width: 2.1875rem;
                height: 2.1875rem;
                border-radius: 3.125rem;
                display: inline-block;
                padding: 0.4375rem 0.5625rem;
                margin-right: 0.625rem;
				@at-root [direction="rtl"]#{&} {
					 margin-right: 0;
					 margin-left: 0.625rem
				}
                &.success {
                    background: $success-light;
                    color: $success;
                }
                &.primary {
                    background: var(--rgba-primary-1);
                    color: var(--primary);
                }
                &.danger {
                    background: $danger-light;
                    color: $danger;
                }
            }
            .notify-time {
                width: 100%;
                margin-right: 0;
                color: $l-ctl;
            }
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 12.5rem;
                margin-bottom: 0;
                margin-top: 0.3125rem;
				@include respond('phone') {
				    max-width: 6.25rem;
				}
            }
        }
        .all-notification {
            display: block;
            padding: 0.9375rem 1.875rem 0;
            // color: $dark;
            text-align: center;
			border-top: 0.0625rem solid $light;
				
            i {
                margin-left: 0.625rem;
            }
        }
    }
	
}



[data-container="boxed"] {
	.search-area{
		display:none!important;
	}
}