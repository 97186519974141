@import "../../abstracts/variable";
@import "../../abstracts/mixin";
[data-sidebar-style="full"][data-layout="vertical"] {
    .deznav{
		
		.metismenu{

			& > li{
				padding:0 2.1875rem;
				& > a{
					font-size: 1.125rem;
					padding: 1.125rem 2.1875rem;
					border-radius: 1.375rem;
					@include transitionMedium;
					
				}
				&.mm-active{

					& > a{
					
						i{
							color: $white;
							font-weight: 300;
						}
					}
				}
				.has-arrow:after{
					right: 1.5rem;
				}
				@include respond ('laptop') {
					padding:0 0.9375rem;	
					& > a{
						font-size: 1rem;
						padding: 0.9375rem 1.25rem;
					}
				}
			}
			
		}
		
	}
	.menu-toggle {
        .nav-header {
            width: 6.5rem;
            z-index: 999;
            .brand-logo {
                padding-left: 0;
                padding-right: 0;
                justify-content: center;
            }
            .nav-control {
				right: -4rem;
                .hamburger {
                    .line {
                        background-color:$black;
                    }
                }
            }
        }
		.copyright,
		.plus-box{
			display:none;
		}
        .header {
            padding-left: 7.5rem;
            width: 100%;
            @at-root [direction="rtl"]#{&} {
                padding: 0 0.9375rem;
                padding-right: 7.5rem;
            }
			.header-content{
				padding-left:5rem;
			}
        }
        .deznav {
            width: 6.5rem;
            overflow: visible;
            position: absolute;
            .nav-text {
                display: none;
            }
            .slimScrollDiv,
            .deznav-scroll {
                overflow: visible !important;
            }
			.header-profile{
				&> a.nav-link{
					padding:0.3125rem 0.3125rem !important;
					.header-info{
						display:none;
					}
					img{
						height:3.75rem;
						width:3.75rem;
						background:none;
					}
				} 
				margin-bottom:0;
			} 
            .metismenu {
			
				
                li {
                    position: relative;
					
                    a {
						background:transparent;
						margin: 0.125rem 0;
						
						svg{
						    max-width: 1.5rem;
							max-height: 1.5rem;
							margin-right: 0;
						}
						&:before{
							content:none;
						}
						i{
							margin: 0;
						}
                    }
                    &>ul {
                        position: absolute;
						left: 5.8rem;
						top: 0;
						width: 12rem;
						z-index: 1001;
						display: none;
						padding-left: 0.0625rem;
						height: auto !important;
						box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
						border-radius: 0.375rem;
						margin-left: 0;
						border:0;
						background:$white;

                        @at-root [direction="rtl"]#{&} {
                            left: auto;
                            right: 5rem;
                            // box-shadow: -0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.15);
                        }
                        li:hover {
                            ul {
                                // display: block;
                                left: 11.8125rem;
                                top: 0;
								&:after{
									content:none;
								}
                            }
                        }
                    }
                    &:hover>ul {
                        display: block;
                        height: auto;
                        overflow: visible;
                    }
                }
                &>li {
                    transition: all 0.4s ease-in-out;
					padding: 0 0.9375rem;
					
                    &>a {
						padding: 1.25rem 1.125rem;
						text-align:center;
						border-radius:3rem;
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
                    }
                    &.mm-active > a{
						background:var(--primary);
						border-radius:1rem;
						box-shadow:none;
						i{
							color:$white;
							padding:0;
						}
					}
                    &:hover{
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto;
                            }
                        }
                        &>a {
							border-radius: 3rem;
							background:var(--primary);
							color:var(--primary);
							@at-root [data-theme-version="dark"]#{&} {
								background:$dark-card;
							}
							i{
								color:$white;
								/* background:$primary; */
							}
                        }
                        &>ul {
                            height: auto !important;
							padding: 0.625rem 0;						
							
                            a {
                                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                                margin-left: -.1rem;
                            }
                            ul{
								padding: 0.625rem 0;
								a {
									padding: 0.375rem 1.25rem 0.375rem 1.25rem;
									margin-left: -.1rem;
								}
                            }
                        }
                    }
                }
                .nav-label,
                .nav-badge {
                    display: none;
					
                }
            }
        }
        .content-body {
            margin-left: 6.5rem;
            @at-root [direction="rtl"]#{&} {
                margin-right: 5.7rem;
                margin-left: auto;
				border: 0;
            }
        }
        &+.footer {
            padding-left: 5.7rem;
            @at-root [direction="rtl"]#{&} {
                padding-left: 0;
                padding-right: 5.7rem;
            }
        }
		
    }
	
}
[data-sidebar-style="full"][data-layout="horizontal"] {
	.header .header-content{
		padding-left: 1.875rem;
	}
}
