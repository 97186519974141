.custom-ekeditor{
	ul{
		padding-left:1.25rem;
		li{
			list-style:unset;
		}
	}
	ol{
		li{
			list-style:decimal;
		}
	}
}